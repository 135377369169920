import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { FiEdit2, FiSave, FiTrash2, FiX } from 'react-icons/fi';
import { GeneralContext, ModalContext } from '~/contexts';
import Numbers from '~/utils/numbers';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Interface
const FormTariffFlag: React.FC<any> = ({ setFlags, id }) => {
    const { setIsOpen, setFooterModal } = useContext(ModalContext);
    const { user } = useContext(GeneralContext);
    //const { TariffFlagData, setTariffFlagData, saveTariffFlag } = useContext(TariffFlagContext);
    const [clientTariffFlag, setClientTariffFlag] = useState<any>();
    const [tariffFlag, setTariffFlag] = useState<any>(setFlags);
    const [newTariffFlag, setNewTariffFlag] = useState<any[]>([]);
    const [clientPCO, setClientPCO] = useState<any>();

    const [modalitys, setModalitys] = useState<any>([
        { name: 'Verde' },
        { name: 'Amarela' },
        { name: 'Vermelha - Patamar 1' },
        { name: 'Vermelha - Patamar 2' },
        { name: 'Escassez Hídrica' }
    ]);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        let data: any = { [name]: value };

        setClientTariffFlag({ ...clientTariffFlag, [name]: (!data[name] ? '' : Number(data[name])) });
    }

    function onChangeFlag(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        let data: any = { [name]: value };

        setClientTariffFlag({ ...clientTariffFlag, [name]: (!data[name] ? '' : data[name]) });
    }

    function onChangeDateCurrent(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        let data: any = { [name]: value };

        setClientTariffFlag({ ...clientTariffFlag, [name]: (!data[name] ? '' : data[name]) });
    }

    function getMonths() {
        let ret: any = [];

        for (let i = 0; i <= 11; i++) {
            let item = {
                month: i + 1,
                name: Dates._getNameMonth(i)
            };

            ret.push(item);
        }

        return ret;
    }

    function getYears() {
        let init = Number(Dates.getYear()),
            ret: any = [];

        for (let i = init; i >= (init - 20); i--) {
            ret.push(i);
        }

        return ret;
    }

    function getMonthName(month: number) {
        month = Number(month);
        return Dates._getNameMonth((month - 1));
    }

    function addTariffFlag() {
        let TariffFlag = JSON.parse(JSON.stringify(tariffFlag));
        const newFlag = {
            id: Date.now(),  // Gera um id único com base no timestamp
            year: "0000",
            month: "01",
            flag: "Bandeira",
            date: "0000-00",
            value: "0,0"
        };

        TariffFlag.push(newFlag);
/*         TariffFlag.sort((a: any, b: any) => {
            if (b.year !== a.year) return a.year - b.year;
            return b.month - a.month;
        }); */
        setTariffFlag(TariffFlag);
    }

    function editPC(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.id}_${elem.year}_${elem.month}_${elem.flag.replace(/\s|-/g, "_")}_${elem.date.toString().slice(0, 7).replace("-", "_")}"]`);
        item?.classList.add('active');

        setClientTariffFlag(elem);
        setClientPCO(elem);
    }

    function deletePC(elem: any) {
        let TariffFlag = JSON.parse(JSON.stringify(tariffFlag));
        console.log("🚀 ~ deletePC ~ TariffFlag:", TariffFlag)
        let index = TariffFlag.findIndex((q: any) => q.year === elem.year && q.month === elem.month && q.price === elem.price);
        console.log("🚀 ~ deletePC ~ index:", index)

        TariffFlag.splice(index, 1);
        TariffFlag.sort(sort_by('year', { name: 'month' }));

        setTariffFlag(TariffFlag);

        api.delete(`${env.host}/api/flags/database/${tariffFlag[index].id} `,  { headers: { Authorization: user.token } })
    }

    function savePC(elem: any) {
    let item = document.querySelector(`div[data-rel="${elem.id}_${elem.year}_${elem.month}_${elem.flag.replace(/\s|-/g, "_")}_${elem.date.toString().slice(0, 7).replace("-", "_")}"]`);
    item?.classList.remove('active');

    let TariffFlagNew = JSON.parse(JSON.stringify(tariffFlag));
    let oldData = clientPCO,
        newData = clientTariffFlag;

    let idx = TariffFlagNew.findIndex(
        (q: any) => q.year===oldData.year && q.month===oldData.month
    );
    TariffFlagNew.splice(idx, 1, newData);

    newData.value = newData.value.replace(",", ".");

    if (newData.value.includes(".")) {
        newData.value = newData.value.replace(",", ".");

        setNewTariffFlag((prevData) => [...prevData, newData]);
        setTariffFlag (TariffFlagNew);
        setClientPCO(null);
        setClientTariffFlag(null);
/*         TariffFlagNew.sort((a: any, b: any) => {
            if (b.year !== a.year) return a.year - b.year;
            return b.month - a.month;
        }); */
    } else {
        alert("Formato Invalido do valor referente a bandeira tarifaria")
    }
    }

    function cancelPC(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.id}_${elem.year}_${elem.month}_${elem.flag.replace(/\s|-/g, "_")}_${elem.date.toString().slice(0, 7).replace("-", "_")}"]`);
        item?.classList.remove('active');
        setClientTariffFlag(null);
    }

    function saveTariffFlag() {
        let newValue = newTariffFlag.map(({ id, ...rest }) => rest);

        api.post(`${env.host}/api/flags/create/database`, newValue, { headers: { Authorization: user.token } })
    }

    useEffect(() => {
        tariffFlag.sort((a: any, b: any) => {
            if (b.year !== a.year) return a.year - b.year;
            return b.month - a.month;
        });
        setFooterModal(<>
            <button id="btnSave" type="button" onClick={() => { setIsOpen(false); saveTariffFlag() }}>Salvar</button>
            <button id="btnCancelem" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
        </>)
    }, [tariffFlag, setTariffFlag])

    return (
        <>
            {
                tariffFlag?.length > 0 ? (
                    <div className="header">
                        <div className="infos">
                            <div className="yearHeader">Ano</div>
                            <div className="monthHeader">Mês</div>
                            <div className="modalityHeader">Modalidade</div>
                            <div className="DateCurrentHeader">Data Vigente </div>
                            <div className="priceHeader">Preço (kWh)</div>
                        </div>

                        <div className="buttons"></div>
                    </div>
                ) : <></>
            }

            {
                tariffFlag?.map((el: any) => (
                    <div className="line" key={`${el.id}_${el.year}_${el.month}_${el.flag.replace(/\s|-/g, "_")}_${el.date.toString().slice(0, 7).replace("-", "_")}`} data-rel={`${el.id}_${el.year}_${el.month}_${el.flag.replace(/\s|-/g, "_")}_${el.date.toString().slice(0, 7).replace("-", "_")}`}>
                        <div className="infos">
                            <div className="item">
                                <div className="year">{el.year}</div>
                                <div className="month">{getMonthName(el.month)}</div>
                                <div className="modality">{el.flag}</div>
                                <div className="DateCurrent">{el.date.toString().slice(0, 7)}</div>
                                <div className="price">{el.value == 0 ? "0,000" : parseFloat(el.value).toFixed(3).replace('.', ',')}</div>
                            </div>

                            <div className="edit">
                                <div className="year">
                                    <select
                                        name="year" id="year" onChange={onChange}
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Ano</option>
                                        <optgroup label="Ano">
                                            {
                                                getYears().map((ye: any) => (
                                                    <option key={`year_${ye}`} value={ye}>{ye}</option>
                                                ))
                                            }
                                        </optgroup>
                                    </select>
                                </div>

                                <div className="month">
                                    <select
                                        name="month" id="month" onChange={onChange}
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Mês</option>
                                        <optgroup label="Meses">
                                            {
                                                getMonths().map((mo: any) => (
                                                    <option key={`month_${mo.name}`} value={mo.month}>{mo.name}</option>
                                                ))
                                            }
                                        </optgroup>
                                    </select>
                                </div>

                                <div className="modality">
                                    <select
                                        name="flag" id="flag" onChange={onChangeFlag}
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Modalidade</option>
                                        <optgroup label="Modalidade">
                                            {
                                                modalitys.map((mo: any) => (
                                                    <option key={`flag_${mo.name}`} value={mo.name}>{mo.name}</option>
                                                ))
                                            }
                                        </optgroup>
                                    </select>
                                </div>

                                <div className="DateCurrent">
                                    <input
                                        id="date"
                                        name="date"
                                        type="date"
                                        defaultValue={el.date ? el.date : 0}
                                        onChange={onChangeDateCurrent}
                                    />
                                </div>

                                <div className="price">
                                    <CurrencyInput
                                        id="value"
                                        name="value"
                                        placeholder="Insira o valor em "
                                        value={clientTariffFlag?.value || '0.0'}
                                        decimalsLimit={3}
                                        onValueChange={(value) => {
                                            setClientTariffFlag((prevState: any) => ({
                                                ...prevState,
                                                value: value
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="item">
                                <button className="edit" onClick={() => editPC(el)} /* disabled={(!clientPCofins ? false : true)} */>
                                    <FiEdit2 size={16} />
                                </button>

                                <button className="del" onClick={() => deletePC(el)} /* disabled={(!clientPCofins ? false : true)} */>
                                    <FiTrash2 size={16} />
                                </button>
                            </div>

                            <div className="edit">
                                <button className="save" onClick={() => savePC(el)}>
                                    <FiSave size={16} />
                                </button>

                                <button className="cancel" onClick={() => cancelPC(el)}>
                                    <FiX size={16} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            }

            <button className="btn add" type="button" onClick={addTariffFlag}>Adicionar bandeira tarifária</button>

        </>
    );
}

export default FormTariffFlag;