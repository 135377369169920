import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from "react-tooltip";
import { FiDollarSign, FiLoader } from 'react-icons/fi';
import { FaChargingStation, FaFlag, FaTimes } from 'react-icons/fa';
import { GeneralContext, ModalContext, PisCofinsContext, ProviderContext } from '~/contexts';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Import Components
import AdminDesign from '~/components/admin';
import ProviderBox from '~/components/parts/ProviderBox';
import { LoadingSingle } from '~/components/default/loading';

// Form
import { FormProvider, FormPisCofins } from '~/forms';
import FormTariffFlag from '~/forms/TariffFlag';

const Providers: React.FC<any> = ({ match }) => {
    const { setIsOpen, setTitleModal, setContentModal, setFooterModal } = useContext(ModalContext);
    const { setPisCofinsData }              = useContext(PisCofinsContext);
    const { setProviderData }               = useContext(ProviderContext);
    const { user }                          = useContext(GeneralContext);
    const [ready, setReady]                 = useState(false);
    const [provDown, setProvDown]           = useState(false);
    const [flagDown, setFlagDown]           = useState(false);
    const [tarrDown, setTarrDown]           = useState(false);
    const [providersList, setProvidersList] = useState([]);
    const [flags, setFlags]                 = useState([]);
    const [providers, setProviders]         = useState([]);
    const [tooltip, setTooltip]             = useState('');
    const [search, setSearch]               = useState('');

    function openModal(modal: any, data?: any) {
        switch (modal) {
            case 'editPisCofins':
                setTitleModal('Editando PisCofins');

                setPisCofinsData(data.pisCofins);
                setContentModal(<FormPisCofins setProviders={setProviders} id={data.id} />);
                break;

            case 'editProvider':
                setTitleModal('Editando Distribuidora');

                let dataProvider = {
                    id    : 0,
                    ref_id: 0,
                    name  : '',
                    nick  : '',
                    point_hours: {
                        init: '',
                        end : ''
                    },
                    active: 1,
                    errors: null
                };

                if (data) {
                    dataProvider.id               = data.id;
                    dataProvider.ref_id           = data.ref_id;
                    dataProvider.name             = data.name;
                    dataProvider.nick             = data.nick;
                    dataProvider.point_hours.init = data.point_hours.init;
                    dataProvider.point_hours.end  = data.point_hours.end;
                    dataProvider.active           = !data.active ? 0: 1;
                }

                setProviderData(dataProvider);
                setContentModal(<FormProvider setProviders={setProviders} />);
                break;

            case 'addFlag':
                setTitleModal('Adicionar Bandeiras Tarifárias (Preço do kWh a cada 1 kWh )');

                setContentModal(<FormTariffFlag setFlags={flags}/>);
                break;
            default:
                setTitleModal('');
                setContentModal(<></>);
                setFooterModal(<></>);
                break;
        }

        setIsOpen(true)
    }

    function providersData () {
        setProvDown(true)

        api.put(`${env.host}/save/api`).then(resp => {
            setProviders(resp.data)
            setProvDown(false)
        })
    }

    function flagData() {

        const currentDate = new Date();
        const pastDate = new Date();
        pastDate.setMonth(currentDate.getMonth() - 6);

        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adiciona zero à esquerda, se necessário
            return `${year}-${month}`;
        };

        const init = formatDate(pastDate);
        const end = formatDate(currentDate);

        api.post(`${env.host}/api/flags/database`, { init, end }, { headers: { Authorization: user.token } })
        .then(resp => {
            setFlags(resp.data);
        });
    }

    function tariffsData () {
        setTarrDown(true)

        api.put(`${env.host}/save/api/tariffs`).then(resp => {
            setTarrDown(false)
        })
    }

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let providersFilter = providersList.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
                (el.nick && el.nick.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
                ? true : false
        )

        setSearch(data)
        setProviders(providersFilter);
    }

    useEffect(() => {
        flagData()
        if (providers.length===0) {
            setReady(false)
            api.get(`${env.host}/api?admin=1`, {headers: { Authorization: user.token }}).then(resp => {
                setProvidersList(resp.data)
                setProviders(resp.data)
                setReady(true)
            })
        }
    }, [providers, tooltip]);

    return (
        <AdminDesign pageTitle="Gerenciamento de Distribuidoras - API Energizou" title="Distribuidoras" subtitle="Gestão de Distribuidoras" className="pv">
            {
                ready ? (
                    <>
                        <div className="headerLine">
                            <div className="buttons">
                                <button
                                    type="button"
                                    className={`providers ${provDown ? 'load' : ''}`}
                                    onClick={() => providersData()}
                                    onMouseOver={() => setTooltip('Atualizar Distribuidoras')}
                                    disabled={provDown}
                                    data-for="tooltip_provider"
                                    data-tip
                                >
                                    { !provDown ? <FaChargingStation size="16" /> : <FiLoader size="16" color="#FFF" strokeWidth="3" /> }
                                </button>

                                <button
                                    type="button"
                                    className={`flags ${flagDown ? 'load' : ''}`}
                                    onClick={() => openModal('addFlag')}
                                    onMouseOver={() => setTooltip('Adicionar Bandeiras Tarifárias ')}
                                    disabled={flagDown}
                                    data-for="tooltip_provider"
                                    data-tip
                                >
                                    { !flagDown ? <FaFlag size="16" type="solid" /> : <FiLoader size="16" color="#FFF" strokeWidth="3" /> }
                                </button>

                                <button
                                    type="button"
                                    className={`tariffs ${tarrDown ? 'load' : ''}`}
                                    onClick={() => tariffsData()}
                                    onMouseOver={() => setTooltip('Atualizar Tarifas')}
                                    disabled={tarrDown}
                                    data-for="tooltip_provider"
                                    data-tip
                                >
                                    { !tarrDown ? <FiDollarSign size="16" /> : <FiLoader size="16" color="#FFF" strokeWidth="3" /> }
                                </button>
                            </div>

                            <div className="searchBox">
                                <input type="text" name="search" id="search" placeholder='Filtrar Distribuidoras...' value={search} onChange={onChangeSearch} />
                                { search!=='' && <button onClick={() => {
                                    setSearch('')
                                    setProviders(providersList)
                                }}>
                                    <FaTimes />
                                </button> }
                            </div>
                        </div>

                        <div className="providers">
                            { providers.map((el: any) => <ProviderBox
                                key={`provider_${el.id}`}
                                setTooltip={setTooltip}
                                editPisCofins={() => openModal('editPisCofins', el)}
                                edit={() => openModal('editProvider', el)}
                                {...el}
                            />) }
                        </div>

                        <ReactTooltip
                            id="tooltip_provider"
                            place="bottom"
                            effect="solid"
                            type="info"
                        >
                            {tooltip}
                        </ReactTooltip>
                    </>
                ) : <LoadingSingle />
            }
        </AdminDesign>
    );
}

export default Providers;