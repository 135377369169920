import React, { Fragment, useContext, useState } from 'react';
import { FiBook, FiCreditCard, FiDatabase, FiDownload, FiLoader, FiServer, FiThermometer } from 'react-icons/fi';
import { GeneralContext, ModalContext } from '~/contexts';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Components
import { LoadingSingle } from '~/components/default/loading';

// Interfaces
import { ClientInt } from '~/utils/interfaces';
import numbers from '~/utils/numbers';
import dates from '~/utils/dates';

// Images
import energizou from '~/assets/images/logo_minimal.svg';
import serena from '~/assets/images/logo_serena_minimal.svg';

interface CompProps {
    setTooltip: Function;
    edit: Function;
    delete: Function;
    token: string;
}

type Props = ClientInt & CompProps;

const Clients: React.FC<Props> = (props) => {
    const {setIsOpen, setTitleModal, setContentModal, setFooterModal} = useContext(ModalContext);
    const {user} = useContext(GeneralContext);
    const [dataDown, setDataDown] = useState(false);

    function capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function treatDate (dateStr: string) {
        let dBase = new Date(dateStr);
        let date  = new Intl.DateTimeFormat('pt-BR').format(dBase);
        return date;
    }

    function treatMonth (month: number) {
        switch (month) {
            case 1:
                return 'Janeiro'
            case 2:
                return 'Fevereiro'
            case 3:
                return 'Março'
            case 4:
                return 'Abril'
            case 5:
                return 'Maio'
            case 6:
                return 'Junho'
            case 7:
                return 'Julho'
            case 8:
                return 'Agosto'
            case 9:
                return 'Setembro'
            case 10:
                return 'Outubro'
            case 11:
                return 'Novembro'
            case 12:
                return 'Dezembro'
        }
    }

    function getData (client: any) {
        let date = new Date();

        setTitleModal(`Dados do Cliente: ${client.name}`)
        setContentModal(<LoadingSingle />)
        setFooterModal()
        setIsOpen(true)

        api.get(`${env.host}/ccee/data/${props.id}/${date.getFullYear() + '-' + ((date.getMonth()+1) < 10 ? '0' : '') + (date.getMonth()+1)}`, {headers: { Authorization: user.token }}).then(resp => {
            let content: any = <></>;

            if (resp.data.length>0) {
                content = resp.data.map((el: any, i: number) => {
                    if (i===0) {
                        return (
                            <Fragment key={`data_header_${i}`}>
                                <div className="header">
                                    <span className='date'>Data</span>
                                    <span className='time'>Hora</span>
                                    <span className='states'>Status</span>
                                    <span className='cons'>Consumo</span>
                                </div>

                                <div className="table-line">
                                    <span className='date'>{treatDate(el.date)}</span>
                                    <span className='time'>{el.time}</span>
                                    <span className='states'>{el.state.name}</span>
                                    <span className='cons'>{el.consumption.active}</span>
                                </div>
                            </Fragment>
                        )
                    }

                    return (
                        <div key={`data_${el.id}_${i}`} className="table-line">
                            <span className='date'>{treatDate(el.date)}</span>
                            <span className='time'>{el.time}</span>
                            <span className='states'>{el.state.name}</span>
                            <span className='cons'>{el.consumption.active}</span>
                        </div>
                    )
                })
            } else {
                content = (
                    <div className="alert">
                        Não há dados cadastrados para esse cliente!
                    </div>
                )
            }

            setTimeout(() => setContentModal(content), 1000);
        })
    }

    function getProinfa (client: any) {
        setTitleModal(`Dados Proinfa: ${client.name}`)
        setContentModal(<LoadingSingle />)
        setFooterModal()
        setIsOpen(true)

        api.get(`${env.host}/ccee/proinfa/${client.id}`, {headers: { Authorization: user.token }}).then(resp => {
            let today = dates.getDate(dates.getNow('yyyy-mm-dd'));
            let content: any = <></>;

            if (resp.data && resp.data.length>0) {
                content = resp.data.map((el: any, i: number) => {
                    if (i===0) {
                        return (
                            <Fragment key={`data_header_${i}`}>
                                {today.year!==el.year || (today.year===el.year && today.month!==el.month) || (today.year===el.year && today.month===el.month && !el.price) && <div className='alertBox'>Não foi possível localizar a tarifa referente ao mês corrente</div>}

                                <div className="header">
                                    <span className='year'>Ano</span>
                                    <span className='month'>Mês</span>
                                    <span className='price'>Valor</span>
                                </div>

                                <div className="table-line">
                                    <span className='year'>{el.year}</span>
                                    <span className='month'>{treatMonth(el.month)}</span>
                                    <span className={`price ${!el.value && 'err'}`}>{numbers.money(el.value, false)}</span>
                                </div>
                            </Fragment>
                        )
                    }

                    return (
                        <div key={`data_${el.id}_${i}`} className="table-line">
                            <span className='year'>{el.year}</span>
                            <span className='month'>{treatMonth(el.month)}</span>
                            <span className={`price ${!el.value && 'err'}`}>{numbers.money(el.value, false)}</span>
                        </div>
                    )
                })
            } else {
                content = (
                    <div className="alert">
                        Não há dados cadastrados para esse cliente!
                    </div>
                )
            }

            setTimeout(() => setContentModal(content), 1000);
        });
    }

    function getFees (client: any) {
        setTitleModal(`Dados Taxa CCEE: ${client.name}`)
        setContentModal(<LoadingSingle />)
        setFooterModal()
        setIsOpen(true)

        api.get(`${env.host}/ccee/fees/${client.id}`, {headers: { Authorization: user.token }}).then(resp => {
            let today = dates.getDate(dates.getNow('yyyy-mm-dd'));
            let content: any = <></>;

            if (resp.data && resp.data.length>0) {
                content = resp.data.map((el: any, i: number) => {
                    if (i===0) {
                        return (
                            <Fragment key={`data_header_${i}`}>
                                {today.year!==el.year || (today.year===el.year && today.month!==el.month) || (today.year===el.year && today.month===el.month && !el.price) && <div className='alertBox'>Não foi possível localizar a tarifa referente ao mês corrente</div>}

                                <div className="header">
                                    <span className='year'>Ano</span>
                                    <span className='month'>Mês</span>
                                    <span className='price'>Valor</span>
                                </div>

                                <div className="table-line">
                                    <span className='year'>{el.year}</span>
                                    <span className='month'>{treatMonth(el.month)}</span>
                                    <span className={`price ${!el.price && 'err'}`}>{numbers.money(el.price, false)}</span>
                                </div>
                            </Fragment>
                        )
                    }

                    return (
                        <div key={`data_${el.id}_${i}`} className="table-line">
                            <span className='year'>{el.year}</span>
                            <span className='month'>{treatMonth(el.month)}</span>
                            <span className={`price ${!el.price && 'err'}`}>{numbers.money(el.price, false)}</span>
                        </div>
                    )
                })
            } else {
                content = (
                    <div className="alert">
                        Não há dados cadastrados para esse cliente!
                    </div>
                )
            }

            setTimeout(() => setContentModal(content), 1000);
        });
    }

    function getBackupEnergy (client: any) {
        setTitleModal(`Dados Energia de Reserva: ${client.name}`)
        setContentModal(<LoadingSingle />)
        setFooterModal()
        setIsOpen(true)

        api.get(`${env.host}/ccee/bkp/${client.id}?order=1`, {headers: { Authorization: user.token }}).then(resp => {
            let today = dates.getDate(dates.getNow('yyyy-mm-dd'));
            let content: any = <></>;

            if (resp.data && resp.data.length>0) {
                content = resp.data.map((el: any, i: number) => {
                    if (i===0) {
                        return (
                            <Fragment key={`data_header_${i}`}>
                                {today.year!==el.year || (today.year===el.year && today.month!==el.month) || (today.year===el.year && today.month===el.month && !el.price) && <div className='alertBox'>Não foi possível localizar a dados referente ao mês corrente</div>}

                                <div className="header">
                                    <span className='year'>Ano</span>
                                    <span className='month'>Mês</span>
                                    <span className='price'>Valor</span>
                                </div>

                                <div className="table-line">
                                    <span className='year'>{el.year}</span>
                                    <span className='month'>{treatMonth(el.month)}</span>
                                    <span className={`price ${!el.price && 'err'}`}>{el.price ? numbers.money(el.price, false) : 'Não informado'}</span>
                                </div>
                            </Fragment>
                        )
                    }

                    return (
                        <div key={`data_${el.id}_${i}`} className="table-line">
                            <span className='year'>{el.year}</span>
                            <span className='month'>{treatMonth(el.month)}</span>
                            <span className={`price ${!el.price && 'err'}`}>{el.price ? numbers.money(el.price, false) : 'Não informado'}</span>
                        </div>
                    )
                })
            } else {
                content = (
                    <div className="alert">
                        Não há dados cadastrados para esse cliente!
                    </div>
                )
            }

            setTimeout(() => setContentModal(content), 1000);
        });
    }

    function getSalesOff (client: any) {
        setTitleModal(`Dados Garantia Financeira: ${client.name}`)
        setContentModal(<LoadingSingle />)
        setFooterModal()
        setIsOpen(true)

        api.get(`${env.host}/ccee/liq/${client.id}?order=1`, {headers: { Authorization: user.token }}).then(resp => {
            let today = dates.getDate(dates.getNow('yyyy-mm-dd'));
            let content: any = <></>;

            if (resp.data && resp.data.length>0) {
                content = resp.data.map((el: any, i: number) => {
                    if (i===0) {
                        return (
                            <Fragment key={`data_header_${i}`}>
                                {today.year!==el.year || (today.year===el.year && today.month!==el.month) || (today.year===el.year && today.month===el.month && !el.price) && <div className='alertBox'>Não foi possível localizar a dados referente ao mês corrente</div>}

                                <div className="header">
                                    <span className='year'>Ano</span>
                                    <span className='month'>Mês</span>
                                    <span className='price'>Valor</span>
                                    <span className='pre'>Pre</span>
                                </div>

                                <div className="table-line">
                                    <span className='year'>{el.year}</span>
                                    <span className='month'>{treatMonth(el.month)}</span>
                                    <span className={`price ${el.price === null && 'err'}`}>{el.price !== null ? numbers.money(el.price, false) : 'Não informado'}</span>
                                    <span className='pre'>{el.pre===1 ? 'Sim' : 'Não'}</span>
                                </div>
                            </Fragment>
                        )
                    }

                    return (
                        <div key={`data_${el.id}_${i}`} className="table-line">
                            <span className='year'>{el.year}</span>
                            <span className='month'>{treatMonth(el.month)}</span>
                            <span className={`price ${el.price === null && 'err'}`}>{el.price !== null ? numbers.money(el.price, false) : 'Não informado'}</span>
                            <span className='pre'>{el.pre===1 ? 'Sim' : 'Não'}</span>
                        </div>
                    )
                })
            } else {
                content = (
                    <div className="alert">
                        Não há dados cadastrados para esse cliente!
                    </div>
                )
            }

            setTimeout(() => setContentModal(content), 1000);
        });
    }

    function downloadData () {
        setDataDown(true)

        api.put(`${env.host}/save/ccee/data?unit=${props.id}&current=1`).then(resp => {
            setDataDown(false)
        })
    }

    return (
        <div className="clientBox">
            <div className="clientData">
                <div className="infos">
                    <div className="logo">
                        <img
                            src={ props.client_type === 'serena' ? serena : energizou }
                            alt={`Tipo do Cliente - ${capitalize(props.client_type)}`}
                        />
                    </div>

                    <h3>{ props.name }</h3>
                    <div className="line">
                        <span><b>Agente:</b> {props.agent}</span>
                        <span><b>Perfil:</b> {props.profile}</span>
                        <span><b>Medidor:</b> {props.meter}</span>
                    </div>
                    <div className="line">
                        <span><b>Contrato Proinfa:</b> {props.proinfa_code ? props.proinfa_code : 'Não Informado'}</span>
                        <span><b>Início do Contrato:</b> {treatDate(props.init + ' 03:00:00')}</span>
                    </div>
                </div>
            </div>

            <div className="buttons">
                <button
                    type="button"
                    className={`download ${dataDown ? 'load' : ''}`}
                    onClick={() => downloadData()}
                    onMouseOver={() => props.setTooltip('Atualizar os Dados')}
                    data-for="tooltip_clients"
                    data-tip
                    disabled={dataDown}
                >
                    { !dataDown ? <FiDownload size={20} /> : <FiLoader size={20} color="#FFF" strokeWidth="3" /> }
                </button>

                <button
                    type="button"
                    className="proinfa"
                    onClick={() => getProinfa(props)}
                    onMouseOver={() => props.setTooltip('Visualizar Proinfa')}
                    data-for="tooltip_clients"
                    data-tip
                >
                    <FiBook size={20} />
                </button>

                <button
                    type="button"
                    className="fees"
                    onClick={() => getFees(props)}
                    onMouseOver={() => props.setTooltip('Visualizar Taxa CCEE')}
                    data-for="tooltip_clients"
                    data-tip
                >
                    <FiThermometer size={20} />
                </button>

                <button
                    type="button"
                    className="bkpEnergy"
                    onClick={() => getBackupEnergy(props)}
                    onMouseOver={() => props.setTooltip('Visualizar Energia de Reserva')}
                    data-for="tooltip_clients"
                    data-tip
                >
                    <FiServer size={20} />
                </button>

                <button
                    type="button"
                    className="salesOff"
                    onClick={() => getSalesOff(props)}
                    onMouseOver={() => props.setTooltip('Visualizar Garantia Financeira')}
                    data-for="tooltip_clients"
                    data-tip
                >
                    <FiCreditCard size={20} />
                </button>

                <button
                    type="button"
                    className="datas"
                    onClick={() => getData(props)}
                    onMouseOver={() => props.setTooltip('Visualizar os Dados')}
                    data-for="tooltip_clients"
                    data-tip
                >
                    <FiDatabase size={20} />
                </button>
            </div>
        </div>
    );
}

export default Clients;